import Vue from "vue";
import i18n from "@/fmt/i18n";

export default class GtsDropdownData extends Vue {
   Enums(filter, withAll = true, maxRange = 1000) {
      let _items = [];
      for (let i = -1; i <= maxRange; i++) {
         if (i == -1 && !withAll) continue;
         if (i == 0) continue;
         let name = filter(i);
         if (
            typeof name !== "undefined" &&
            !name.toUpperCase().endsWith("UNKNOWN") &&
            !name.toUpperCase().endsWith("TANIMSIZ") &&
            !name.toUpperCase().endsWith("UNBEKANNT") &&
            !name.toUpperCase().includes("PENDING")
         )
            _items.push({ value: i, name: name });
      }
      return _items;
   }

   ETaskType() {
      return this.Enums(this.$options.filters.ETaskType);
   }

   EComGender() {
      return this.Enums(this.$options.filters.EComGender, false);
   }

   ETaskStatus() {
      return this.Enums(this.$options.filters.ETaskStatus);
   }

   EReportHotelDateType() {
      return this.Enums(this.$options.filters.EReportHotelDateType, false);
   }

   EOdrSearchHotelDateType() {
      return this.Enums(this.$options.filters.EOdrSearchHotelDateType, false);
   }

   EReportTransferDateType() {
      return this.Enums(this.$options.filters.EReportTransferDateType, false);
   }

   EOdrSearchRentACarDateType() {
      return this.Enums(this.$options.filters.EOdrSearchRentACarDateType, false);
   }

   EReportRentACarDateType() {
      return this.Enums(this.$options.filters.EReportRentACarDateType, false);
   }

   EReportRentACarReportType() {
      return this.Enums(this.$options.filters.EReportRentACarReportType, false);
   }

   EFltPnrStatus() {
      return this.Enums(this.$options.filters.EFltPnrStatus);
   }

   EHtlVoucherStatus(withAll, maxRange) {
      return this.Enums(this.$options.filters.EHtlVoucherStatus, withAll, maxRange);
   }

   ETrfTnrStatus() {
      return this.Enums(this.$options.filters.ETrfTnrStatus);
   }

   ERacRnrStatus() {
      return this.Enums(this.$options.filters.ERacRnrStatus);
   }
   ETrfFacilityType() {
      return this.Enums(this.$options.filters.ETrfFacilityType);
   }
   dateMonthArray() {
      return [
         { name: i18n.t("common_january"), value: 0 },
         { name: i18n.t("common_february"), value: 1 },
         { name: i18n.t("common_march"), value: 2 },
         { name: i18n.t("common_april"), value: 3 },
         { name: i18n.t("common_may"), value: 4 },
         { name: i18n.t("common_june"), value: 5 },
         { name: i18n.t("common_july"), value: 6 },
         { name: i18n.t("common_august"), value: 7 },
         { name: i18n.t("common_september"), value: 8 },
         { name: i18n.t("common_october"), value: 9 },
         { name: i18n.t("common_november"), value: 10 },
         { name: i18n.t("common_december"), value: 11 },
      ];
   }
}
